import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {CardService} from "../../shopping-cart/card.service";
import {AuthService} from "../../../auth/auth.service";
import {ProductsContentItemModel} from "../../../../core/interfaces/products/products-data.model";
import {WishListDataModel} from "../../../../core/interfaces/wish-list/wish-list-data.model";
import {WishListService} from "../wish-list.service";
import {ShoppingCartReqModel} from "../../../../core/interfaces/shopping-cart/shopping-cart.model";
import {Base} from "../../../../core/base";
import {takeUntil} from "rxjs/operators";
import {MatDialogRef} from "@angular/material/dialog";
import {ChangeWishListNameComponent} from "../change-wish-list-name/change-wish-list-name.component";
import {QuickviewService} from "../../../../core/services/quick-view.service";
import {environment} from "../../../../../environments/environment";
import {FormControl, Validators} from "@angular/forms";

@Component({
    selector: 'app-wish-list-page',
    templateUrl: './wish-list-page.component.html'
})
export class WishListPageComponent extends Base implements OnInit {
    wishList: WishListDataModel[] = [];
    list: ProductsContentItemModel[] = [];
    supplierId: string;
    listId: string;
    productCount = 1;
    shopId: string;
    imagePath = environment.imagePath;
    selectedList: string;
    listName: string;
    dialogRef: MatDialogRef<ChangeWishListNameComponent>
    shop: any;
    isLoading: boolean;
    showingQuickview: boolean;
    showWishListEditPopup: boolean;
    addListControl: FormControl;

    constructor(private wishListService: WishListService,
                private authService: AuthService,
                private cdRef: ChangeDetectorRef,
                private quickview: QuickviewService,
                public cardService: CardService) {
        super();
    }

    ngOnInit(): void {
        this.addListControl = new FormControl('', Validators.required);
        this.authService.changeShopAddress.pipe(takeUntil(this.destroy$)).subscribe(res => {
            this.shopId = res.id;
            this.getWishList();
            this.getCards();
        })
        this.getChangedWishList();
    }

    private getChangedWishList(): void {
        this.wishListService.changeWishListName$.pipe(takeUntil(this.destroy$)).subscribe({
            next: (res) => {
                if (res) {
                    this.getWishList();
                }
            }
        })
    }

    private getWishList(): void {
        this.isLoading = true;
        this.wishListService.getWishList().pipe(takeUntil(this.destroy$)).subscribe({
            next: res => {
                this.wishList = res.payload.content;
                this.getWishListById(this.wishList[0]?.id.toString());
                this.isLoading = false;
                this.cdRef.markForCheck();
            },
            error: () => {
                this.isLoading = false;
            }
        })
    }

    getWishListById(id: string): void {
        this.wishListService.getWishListById(id, this.shopId).pipe(takeUntil(this.destroy$)).subscribe(res => {
            this.list = res.payload.products;
            this.listName = res.payload.name;
            this.listId = id;
            this.isLoading = false;
        })
    }

    addToCard(product): void {
        const body: ShoppingCartReqModel = {
            shop: this.shopId,
            productId: product.id,
            quantity: this.productCount,
            properties: {
                unit: {
                    unitId: product?.properties?.unit[0]?.unitId,
                    name: product?.properties?.unit[0]?.name,
                    refId: product?.properties?.unit[0]?.refId,
                    contents: product?.properties?.unit[0]?.contents
                }
            }
        }

        if (product?.properties?.unit?.length > 1) {
            if (this.showingQuickview) {
                return;
            }

            this.showingQuickview = true;
            this.quickview.show(product).subscribe({
                complete: () => {
                    this.showingQuickview = false;
                },
            });
        } else {
            this.cardService.addToCard(body).pipe(takeUntil(this.destroy$)).subscribe(() => {
                this.getCards();
            });
        }
    }

    private getCards(): void {
        this.cardService.getCards({shopId: this.shopId}).pipe(takeUntil(this.destroy$)).subscribe(() => {
        })
    }

    removeFromWishList(id: string): void {
        this.wishListService.removeFromFavorite(this.listId, id).pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.getWishListById(this.listId);
            this.getWishList();
        })
    }

    steQuantityByKeyDown(event: string, id: string): void {
        const product = this.list.find(p => p.id === id);
        if (product) {
            product.quantity = +event;
            this.productCount = product.quantity;
        }
    }

    removeList(id: string): void {
        this.wishListService.deleteList(id).pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.getWishList();
        })
    }

    changeListName(list: WishListDataModel): void {
        if (this.showWishListEditPopup) {
            return;
        }

        this.showWishListEditPopup = true;
        this.wishListService.showEditWishListName(list).subscribe({
            complete: () => {
                this.showWishListEditPopup = false;
            },
        });
    }

    addWishList(): void {
        this.isLoading = true;
        this.wishListService.createWishList({name: this.addListControl.value}).pipe(takeUntil(this.destroy$)).subscribe({
            next: () => {
                this.getWishList();
                this.addListControl.reset();
            }
        })
            .add(() => this.isLoading = false);
    }
}
