import {Component, OnInit, ViewChild} from '@angular/core';
import {Location} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import {MatTable, MatTableDataSource} from "@angular/material/table";
import {DashboardService} from "../../../dashboard.service";
import {Base} from "../../../../../core/base";
import {takeUntil} from "rxjs/operators";
import {ApiResultModel} from "../../../../../core/interfaces/api-result.model";
import {GetOrderByIdResModel} from "../../../../../core/interfaces/order/order.model";
import {TranslationKeys} from "../../../../../core/data/translation-keys";
import {AppConfig} from "../../../../../core/config/appConfig";
import {OrderStatusesEnum} from "../../../../../core/enums/order-statuses.enum";
import {MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {ChangeOrderStatusComponent} from "../../dialogs/change-order-status/change-order-status.component";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";

@Component({
	selector: 'app-orders-edit',
	templateUrl: './orders-edit.component.html'
})
export class OrdersEditComponent extends Base implements OnInit {
	@ViewChild(MatTable) table: MatTable<any>;
	orderId: string;
	index: string;
	clicked: boolean = false;
	isCustomer: boolean;
	isOrderChanged: boolean;
	reqData: any[] = [];
	order: GetOrderByIdResModel;
	orderStatuses = OrderStatusesEnum;
	dataSource: MatTableDataSource<any>;
	dialogRef: MatDialogRef<ChangeOrderStatusComponent>
	displayedColumns: string[] = ['product', 'quantity', 'price', 'discount', 'total', 'action'];
	isLoading: boolean;
	dateFormat = AppConfig.constants.longDate;

	constructor(private location: Location,
				private activateRouter: ActivatedRoute,
				private dashboardService: DashboardService,
				private toastr: ToastrService,
				private translate: TranslateService,
				private router: Router,
				private dialog: MatDialog) {
		super();
	}

	ngOnInit(): void {
		this.orderId = this.activateRouter.snapshot.queryParams.id;
		this.getOrderById();
	}

	goToList(): void {
		this.router.navigateByUrl('dashboard/order-list');
	}

	private getOrderById(): void {
		this.isLoading = true;
		this.dashboardService.getOrdersById(this.orderId).pipe(takeUntil(this.destroy$)).subscribe({
			next: (res: ApiResultModel<GetOrderByIdResModel>) => {
				this.order = res.payload
				this.isLoading = false;
				this.reqData = res.payload.orderItems;
				this.dataSource = new MatTableDataSource(res.payload.orderItems);
			},
			error: () => {
				this.isLoading = false;
			}
		})
	}

	delete(event: any[]): void {
		const index = this.reqData.indexOf(event);
		this.reqData.splice(index, 1);
		this.table.renderRows();
		this.toastr.success(this.translate.instant(TranslationKeys.DeleteOrder));
	}

	edit(e: any, row: any): void {
		row.quantity = +e.target.value;
		this.isOrderChanged = e.target.value != row.quantity;
	}

	save(): void {
		const editOrderBody = {
			orderItems: this.reqData
		}
		this.dashboardService.editOrder(this.orderId, editOrderBody).pipe(takeUntil(this.destroy$)).subscribe({
			next: () => {
				this.toastr.success(this.translate.instant(TranslationKeys.EditOrder));
				this.router.navigateByUrl('dashboard/order-list');
			},
			error: (error) => {
				this.toastr.error(this.translate.instant(error.error.message));
			}
		})
	}

	changeStatus(status: string): void {

		const dialogConfig = new MatDialogConfig();
		this.dialogRef = this.dialog.open(ChangeOrderStatusComponent, {
		})

		this.dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe({
			next: (res: true) => {
				if (res) {
					this.dashboardService.editOrderStatus(this.order?.id, { status: status, comment: ''}).pipe(takeUntil(this.destroy$))
						.subscribe({
							next: () => {
								this.toastr.success(this.translate.instant(TranslationKeys.UpdateOrderStatus));
								this.location.back();
							},
							error: (err: any) => {
								this.toastr.error(this.translate.instant(err.error.message));
							}
						})
				}
			}
		})
	}
}
