<ng-container>
	<div class="product-card__actions-list">
		<button
			class="product-card__action product-card__action--quickview"
			type="button"
			[class.product-card__action--loading]="showingQuickview"
			[attr.aria-label]="'BUTTON_QUICKVIEW'|translate"
			(click)="showQuickView($event)"
		>
			<app-icons icon="quickview-16"></app-icons>
		</button>

		<ng-container *ngIf="!exclude.includes('actions')">
			<button
				type="button"
				class="product-card__action product-card__action--wishlist"
				[class.product-card__wishlist--loading]="wishlistInProgress"
				[attr.aria-label]="'BUTTON_ADD_TO_WISHLIST'|translate"
				(click)="addToWishList($event, product)"
			>
				<app-icons icon="wishlist-16"></app-icons>
			</button>
		</ng-container>
	</div>

	<div class="product-card__image">
		<div class="image image--type--product">
			<a [routerLink]="['/product/product-item']" [queryParams]="{ id: product.id }" class="image__body">
				<img class="image__tag"
					 [src]="product?.gallery?.length ? (imagePath + product?.gallery[0]?.medium?.url) : ('assets/images/categories/category-1.jpg')"
					 [alt]="product?.name">
			</a>
		</div>
	</div>

	<div class="product-card__info mt-1">
		<div *ngIf="!exclude.includes('meta')" class="product-card__meta">
			<a (click)="goToSupplier(product?.supplier?.id)">
				<span class="product-card__meta-title" style="color: red">{{product?.supplier?.companyName}}</span>
			</a>
		</div>

		<div class="product-card__name">
			<div>
				<a [routerLink]="['/product/product-item']" [queryParams]="{ id: product.id }">{{ product?.name| textTruncate: 160 }}</a>
			</div>
			<div class="product-card__badges">
				<div *ngIf="product?.discount" class="tag-badge tag-badge--sale">{{'TEXT_BADGE_SALE' | translate}}</div>
			</div>
		</div>

		<div class="product-card__rating">
			<!--			<app-rating class="product-card__rating-stars" [value]="product.rating || 0"></app-rating>-->
			<!--			<div class="product-card__rating-label">-->
			<!--				{{ 'TEXT_RATING_LABEL'|translate:{rating: product.rating, reviews: product.reviews} }}-->
			<!--			</div>-->
		</div>
	</div>

	<div class="product-card__footer">
		<div class="product-card__prices">
			<div class="product-card__price product-card__price--current">
				<ng-container class="d-flex">
					<span class="product-card__price product-card__price--new"
						  *ngIf="product?.discount > 0">{{product?.price - (product?.price * product?.discount) / 100 | currency: ['']}}
						֏</span>
					<span class="product-card__price product-card__price--old"
						  *ngIf="product?.discount > 0">{{ product?.price}}
						֏</span>
				</ng-container>
				<span *ngIf="product?.price && (!product?.discount || product?.discount === 0)">{{ product?.price}}
					֏</span>
			</div>
		</div>

		<ng-container *ngIf="!exclude.includes('buttons')">
			<button
				type="button"
				class="product-card__addtocart-icon"
				[class.product-card__addtocart-icon--loading]="cartInProgress"
				[attr.aria-label]="'BUTTON_ADD_TO_CART'|translate"
				(click)="addToCard($event, product.id)"
			>
				<app-icons icon="cart-20"></app-icons>
			</button>
			<ng-container *ngIf="!exclude.includes('list-buttons')">
				<button
					type="button"
					class="product-card__addtocart-full"
					[class.product-card__addtocart-full--loading]="cartInProgress"
					(click)="addToCard($event, product.id)"
				>
					{{ 'BUTTON_ADD_TO_CART'|translate }}
				</button>
				<button
					type="button"
					class="product-card__wishlist"
					[class.product-card__wishlist--loading]="wishlistInProgress"
					(click)="addToWishList($event, product)"
				>
					<app-icons icon="wishlist-16"></app-icons>
					<span>{{ 'BUTTON_ADD_TO_WISHLIST'|translate }}</span>
				</button>
			</ng-container>
		</ng-container>
	</div>
	<div *ngIf="notShipped" class="product-card__footer" style="color: red">{{'NOT_SHIPPED' | translate}}</div>
	<div class="m-2">
		<app-timer
			*ngIf="blockSale"
			[discountEnd]="product?.discountEnd"></app-timer>
	</div>

</ng-container>
