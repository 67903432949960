<div class="card flex-grow-1 mb-md-0 mr-0 mr-lg-3 ml-0 ml-lg-4">
	<div class="card-body card-body--padding--2">
		<form [formGroup]="loginForm">
			<div class="d-flex flex-column">
				<mat-form-field appearance="outline">
					<mat-label>{{ 'INPUT_PHONE_NUMBER_LABEL'|translate }}</mat-label>
					<ngx-mat-intl-tel-input
						formControlName="login"
						[onlyCountries]="['am']"
						name="mobile">
					</ngx-mat-intl-tel-input>
				</mat-form-field>
			</div>
			<div class="d-flex flex-column form-group">
				<mat-form-field appearance="outline">
					<mat-label>{{ 'INPUT_PASSWORD_LABEL'|translate }}</mat-label>
					<input matInput [type]="hide? 'password': 'text'" placeholder="{{'TYPE_PASSWORD' | translate}}"
						   formControlName="password">
					<button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
						<mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
					</button>
				</mat-form-field>
				<mat-error class="mt-2" *ngIf="notExist">{{errorMessage | translate}}</mat-error>
				<div class="d-flex flex-row justify-content-end">
					<a class="link-primary" (click)="forgotPassword()">
						{{'LINK_FORGOT_PASSWORD'|translate }}
					</a>
				</div>
			</div>
			<div class="d-flex flex-row justify-content-end">
				<button class="btn btn-primary mt-3" (click)="login()">
					{{'BUTTON_LOGIN' | translate}}
				</button>
			</div>
			<div class="d-flex flex-row justify-content-end">
				<button class="btn btn-secondary mt-3" type="button" (click)="goToReg()">
					{{'BUTTON_REGISTER'|translate }}
				</button>
			</div>
		</form>
	</div>
</div>
