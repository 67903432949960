import {Component, HostBinding, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {CardService} from "../../shopping-cart/card.service";
import {Location} from "@angular/common";
import {AuthService} from "../../../auth/auth.service";
import {ShoppingCartItemsModel} from "../../../../core/interfaces/shopping-cart/shopping-cart.model";
import {ShopModel} from "../../../../core/interfaces/shop.model";
import {SupplierModel} from "../../../../core/interfaces/users/supplier/supplier.model";
import {Base} from "../../../../core/base";
import {takeUntil} from "rxjs/operators";
import {ApiResultModel} from "../../../../core/interfaces/api-result.model";
import {CreateOrderResModel} from "../../../../core/interfaces/order/order.model";
import {TranslationKeys} from "../../../../core/data/translation-keys";
import {FormControl, FormGroup} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {environment} from "../../../../../environments/environment";

@Component({
	selector: 'app-order-review',
	templateUrl: './order-review.component.html'
})
export class OrderReviewComponent extends Base implements OnInit {
	@HostBinding('class.card') classCard = true;
	@HostBinding('class.card--loading') get classCardLoading(): boolean {
		return false;
	}
	@HostBinding('class.address-card') classAddressCard = true;
	@HostBinding('class.address-card--featured') get classAddressCardFeatured(): boolean {
		return false;
	}
	shoppingCardId: string;
	cardItems: ShoppingCartItemsModel[] = [];
	showErrorMessage: boolean;
	errorMessage: string;
	showSuccessCreated: boolean;
	imagePath = environment.imagePath;
	totalAmount: number;
	totalReward: number;
	orderId: number;
	shop: ShopModel | any;
	supplier: SupplierModel;
	shops = JSON.parse(localStorage.getItem('shop'));
	shopId  = this.shops.id;
	orderForm: FormGroup = new FormGroup({
		comment: new FormControl(),
		shippingDate: new FormControl(),
		shoppingCart: new FormControl()
	});
	minDate = new Date();

	constructor(private activatedRoute: ActivatedRoute,
				private cardService: CardService,
				private location: Location,
				private router: Router,
				public translate: TranslateService,
				private authService: AuthService,
				private toastr: ToastrService) {
		super();
	}

	ngOnInit(): void {
		this.authService.changeShopAddress.pipe(takeUntil(this.destroy$)).subscribe(res => {
			this.shopId = res.id;
			this.getCards();
		})

		this.shoppingCardId = this.activatedRoute.snapshot.queryParams.id;
		this.orderForm.get('shoppingCart').setValue(this.shoppingCardId);
		this.getShoppingCardById();
	}

	private getShoppingCardById(): void {
		this.cardService.getCardById(this.shoppingCardId).pipe(takeUntil(this.destroy$)).subscribe({
			next: (res) => {
				this.cardItems = res.payload.cartItems;
				this.totalReward = res.payload.totalReward;
				this.totalAmount = res.payload.cartTotal;
				this.shop = res.payload.shop;
				this.supplier = res.payload.supplier;
			},
			error: () => {
				this.router.navigateByUrl('/');
			}
		})
	}

	createOrder(): void {
		let comment = this.orderForm.controls['comment'].value;
		this.cardService.createOrder(this.orderForm.getRawValue()).pipe(takeUntil(this.destroy$)).subscribe( {
			next: (res: ApiResultModel<CreateOrderResModel>) => {
				this.toastr.success(this.translate.instant(TranslationKeys.CreateOrder));
				this.getCards();
				this.router.navigate(['/order/create-order'], {queryParams: {id: res.payload.id }});
			},
			error: (err) => {
				this.showErrorMessage = true;
				this.errorMessage = err.error.message;
				this.toastr.error(this.translate.instant(this.errorMessage));
			}
		})
	}

	goBack(): void {
		this.location.back();
	}

	private getCards(): void {
		this.cardService.getCards({shopId: this.shopId}).pipe(takeUntil(this.destroy$)).subscribe(() => {})
	}
}
