<div class="ng-star-inserted">
	<div class="card ng-star-inserted">
		<div class="order-header">
			<div class="order-header__actions">
				<a class="btn btn-primary btn-sm m-1" (click)="goToList()">{{'BACK_TO_LIST' | translate}}</a>
			</div>
			<h2 class="order-header__title">{{'TEXT_ORDER_WITH_NUMBER' | translate}}{{' #' + order?.orderNumber}}</h2>
			<div class="order-header__subtitle">
				<ul style="list-style: none; padding: 0">
					<li>{{'CREATED_DATE_TITLE' | translate}}<b>{{order?.createdAt | localizedDate: dateFormat}}</b></li>
					<li>{{'UPDATED_DATE_TITLE' | translate}}<b>{{order?.updatedAt | localizedDate: dateFormat}}</b></li>
					<li>{{'STATUS' | translate}}<b>{{': ' + (order?.status | translate)}}</b></li>
				</ul>
			</div>
		</div>
		<div class="order-divider"></div>
		<div class="card-table">
			<div class="table-responsive-sm">
				<table mat-table [dataSource]="dataSource">

					<ng-container matColumnDef="product">
						<th mat-header-cell *matHeaderCellDef
							style="color: black; font-weight: bold"> {{'TABLE_PRODUCT' | translate}} </th>
						<td mat-cell *matCellDef="let row"> {{row.productName}} </td>
						<td mat-footer-cell *matFooterCellDef><b>{{ 'TABLE_TOTAL' | translate }}</b></td>
					</ng-container>

					<ng-container matColumnDef="quantity">
						<th mat-header-cell *matHeaderCellDef
							style="color: black; width: 100px; font-weight: bold"> {{'TABLE_QUANTITY' | translate}} </th>
						<td mat-cell *matCellDef="let row">
							<input
								[readonly]="order?.status !== orderStatuses.Pending"
								[value]="row.quantity"
								(input)="edit($event, row)"
								min=1
								onlyNumber
								class="input-number__input form-control"
								type="number">
						</td>
						<td mat-footer-cell *matFooterCellDef></td>
					</ng-container>
					<ng-container matColumnDef="price">
						<th mat-header-cell *matHeaderCellDef
							style="color: black; font-weight: bold"> {{'TABLE_PRICE' | translate}} </th>
						<td mat-cell *matCellDef="let row"> {{row.price}} ֏</td>
						<td mat-footer-cell *matFooterCellDef></td>
					</ng-container>

					<ng-container matColumnDef="discount">
						<th mat-header-cell *matHeaderCellDef
							style="color: black; font-weight: bold"> {{'TABLE_DISCOUNT' | translate}} </th>
						<td mat-cell *matCellDef="let row"> {{row.discount}} %</td>
						<td mat-footer-cell *matFooterCellDef></td>
					</ng-container>

					<ng-container matColumnDef="total">
						<th mat-header-cell *matHeaderCellDef
							style="color: black; font-weight: bold"> {{'SUM' | translate}} </th>
						<td mat-cell *matCellDef="let row"> {{(row?.price * ((100 - row?.discount) / 100)) * row?.quantity * (+row?.properties?.unit?.contents) | number: '1.0-2'}} ֏</td>
						<td mat-footer-cell *matFooterCellDef><b>{{order?.orderTotal | number: '1.0-2'}} ֏</b></td>
					</ng-container>

					<ng-container matColumnDef="action">
						<th mat-header-cell *matHeaderCellDef></th>
						<td mat-cell *matCellDef="let row">
							<button *ngIf="order?.status === orderStatuses.Pending" type="button"
									(click)="delete(row)" style="border: none; color: red">
								<mat-icon>delete</mat-icon>
							</button>
						</td>
						<td mat-footer-cell *matFooterCellDef></td>
					</ng-container>
					<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
					<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
					<tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
				</table>
				<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
			</div>
		</div>
	</div>
	<div class="row mt-3 no-gutters mx-n2">
		<div class="d-flex justify-content-sm-end">
			<button *ngIf="
			order?.status === orderStatuses.Pending ||
			order?.status === orderStatuses.Updated ||
			order?.status === orderStatuses.Accepted" class="btn btn-secondary m-1" (click)="changeStatus(orderStatuses.Canceled)">{{'BUTTON_CANCEL_ORDER' | translate}}</button>
			<button *ngIf="order?.status === orderStatuses.Pending" class="btn btn-primary btn-sm m-1" (click)="save()">{{'BUTTON_CHANGE' | translate}}</button>
			<button *ngIf="order?.status === orderStatuses.Updated" class="btn btn-primary btn-sm m-1" (click)="changeStatus(orderStatuses.Pending)">{{'BUTTON_ACCEPT' | translate}}</button>
		</div>
	</div>
</div>



