<ng-container *ngIf="!wishList.length && !isLoading">
    <div class="block-empty" style="margin-top: 3rem">
        <div class="container">
            <div class="block-empty__body">
                <h1 class="block-empty__title"
                    style="font-weight: bold">{{ 'HEADER_WISHLIST_EMPTY_TITLE'|translate }}</h1>
                <p>{{ 'HEADER_WISHLIST_EMPTY_SUBTITLE'|translate }}</p>
                <div class="block-empty__action">
                    <a routerLink="/" class="btn btn-primary btn-sm">{{ 'BUTTON_GO_TO_HOMEPAGE'|translate }}</a>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<div *ngIf="wishList.length && !isLoading" class="block-header block-header--has-title block-header--has-breadcrumb">
    <div class="container">
        <div class="block-header__body">
            <div class="block-header__breadcrumb breadcrumb ng-star-inserted"></div>
            <div class="d-flex flex-column">
                <h1 style="margin: 0 auto; font-weight: bold; font-size: 2.25rem">{{'HEADER_WISHLIST' | translate}}</h1>
                <h3 class="block-header__title" style="margin:  auto">{{listName}}</h3>
            </div>
        </div>
    </div>

    <div class="block">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-3 d-flex">
                    <div class="account-nav flex-grow-1">
                        <ul class="account-nav__list">
                            <li class="account-nav__item d-flex"
                                [class.isActiveLi]="listId === lists.id"
                                *ngFor="let lists of wishList; let i = index">
                                <a class="truncate w-100" (click)="getWishListById(lists.id)">
                                    <span>{{lists.name}} ({{lists.products.length}})</span></a>
                                <a class="p-1" [matMenuTriggerFor]="menu">
                                    <mat-icon>more_vert</mat-icon>
                                </a>

                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item (click)="changeListName(lists)">
                                        <mat-icon>edit</mat-icon>
                                        <span>{{'EDIT_LIST_NAME' | translate}}</span>
                                    </button>
                                    <button mat-menu-item (click)="removeList(lists.id)">
                                        <mat-icon>delete</mat-icon>
                                        <span>{{'DELETE_LIST' | translate}}</span>
                                    </button>
                                </mat-menu>
                            </li>
                        </ul>
                        <div class="d-flex justify-content-end align-items-center">
                            <div class="quickview__product-actions-item w-100">
                                <input type="text" class="form-control" [formControl]="addListControl">
                            </div>
                            <div class="quickview__product-actions-item quickview__product-actions-item--addtocart">
                                <div class="d-flex flex-row justify-content-sm-end">
                                    <button [disabled]="addListControl.invalid" (click)="addWishList()" class="btn btn-primary btn-block">
                                        +
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-9 mt-4 mt-lg-0">
                    <ng-container>
                        <div class="block">
                            <div class="container container--max--xl">
                                <div class="wishlist">
                                    <table class="wishlist__table">
                                        <thead class="wishlist__head">
                                        <tr class="wishlist__row wishlist__row--head" *ngIf="list.length > 0">
                                            <th class="wishlist__column wishlist__column--head wishlist__column--image"></th>
                                            <th class="wishlist__column wishlist__column--head wishlist__column--product">{{ 'TABLE_PRODUCT'|translate }}</th>
                                            <th class="wishlist__column wishlist__column--head wishlist__column--price">{{ 'TABLE_PRICE'|translate }}</th>
                                            <th class="cart-table__column cart-table__column--total">{{ 'INPUT_DISCOUNT_LABEL'|translate }}</th>
                                            <th class="wishlist__column wishlist__column--head wishlist__column--button"></th>
                                            <th class="wishlist__column wishlist__column--head wishlist__column--remove"></th>
                                        </tr>
                                        </thead>
                                        <tbody *ngIf="list.length === 0" class="wishlist__body">
                                        <tr>
                                            <td class="wishlist__column wishlist__column--body w-100">
                                                <h1 class="block-empty__title">{{ 'HEADER_WISHLIST_EMPTY_TITLE'|translate }}</h1>
                                            </td>
                                        </tr>
                                        </tbody>
                                        <tbody *ngIf="list.length > 0" class="wishlist__body">
                                        <tr *ngFor="let product of list;"
                                            class="wishlist__row wishlist__row--body">
                                            <td class="wishlist__column wishlist__column--body wishlist__column--image">
                                                <div class="image image--type--product">
                                                    <a [routerLink]="['/product/product-item']" [queryParams]="{ id: product.id }" class="image__body">
                                                        <img class="image__tag"
                                                             [src]="product?.gallery.length ? imagePath + product?.gallery[0]?.thumbnail?.url : 'assets/images/categories/category-1.jpg'"
                                                             alt="">
                                                    </a>
                                                </div>
                                            </td>
                                            <td class="wishlist__column wishlist__column--body wishlist__column--product">
                                                <div class="wishlist__product-name">
                                                    <a [routerLink]="['/product/product-item']" [queryParams]="{ id: product.id }">{{ product?.name }}</a>
                                                </div>
                                            </td>
                                            <td class="wishlist__column wishlist__column--body wishlist__column--price"
                                                [attr.data-title]="'TABLE_PRICE'|translate">
                                                ֏{{(((product?.discount > 0) ? (product?.price - (product?.price * product?.discount) / 100) : product?.price) || '')}}
                                            </td>
                                            <td class="cart-table__column cart-table__column--total"
                                                [attr.data-title]="'INPUT_DISCOUNT_LABEL'|translate">
                                                {{ product?.discount || 0 }}%
                                            </td>
                                            <td class="wishlist__column wishlist__column--body wishlist__column--button">
                                                <button
                                                        [disabled]="product?.quantity < 1"
                                                        appAddToCart
                                                        type="button"
                                                        class="btn btn-sm btn-primary"
                                                        (click)="addToCard(product)"
                                                >
                                                    {{ 'BUTTON_ADD_TO_CART'|translate }}
                                                </button>
                                            </td>
                                            <td class="wishlist__column wishlist__column--body wishlist__column--remove">
                                                <button
                                                        type="button"
                                                        class="wishlist__remove btn btn-sm btn-muted btn-icon"
                                                        (click)="removeFromWishList(product?.id)"
                                                >
                                                    <app-icons icon="cross-12"></app-icons>
                                                </button>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>

<app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>
<div class="block-space block-space--layout--before-footer"></div>
