<div class="modal fade" tabindex="-1" role="dialog" bsModal #modal="bs-modal">

	<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable quickview">
		<form [formGroup]="shopForm">
			<div *ngIf="shops" class="modal-content">

				<div class="modal-header">
					<div>
						<h2 class="modal-title">{{'SELECT_SHOP' | translate}}</h2>
						<mat-form-field appearance="outline">
							<mat-label>{{'SEARCH' | translate}}</mat-label>
							<input matInput formControlName="search">
						</mat-form-field>
					</div>
					<button type="button" class="quickview__close" (click)="onClose()">
						<app-icons icon="cross-12"></app-icons>
					</button>
				</div>

				<div class="modal-body">
					<mat-radio-group
							aria-labelledby="example-radio-group-label"
							class="example-radio-group"
							class="d-flex flex-column">
						<mat-radio-button class="example-radio-button"
										  (change)="onSelection($event.value)"
										  [checked]="shop.isChecked"
										  *ngFor="let shop of updatedList" [value]="shop">
							{{shop.shopName}} <span *ngIf="shop.companyName">({{shop.companyName}})</span>
						</mat-radio-button>
					</mat-radio-group>
				</div>
				<div class="d-flex justify-content-between">
					<div class="modal-footer">
						<button class="btn btn-primary" (click)="goToCreateShop()">
							{{'CREATE_SHOP' | translate}}
						</button>
					</div>
					<div class="modal-footer">
						<mat-paginator [pageSizeOptions]="[10, 25, 100]" [length]="pagination?.totalRecords"
									   (page)="pageEvent = $event; onPaginateChange($event)"
									   showFirstLastButtons></mat-paginator>
						<div class="d-flex flex-row justify-content-sm-end mt-3">
							<button class="btn btn-primary" (click)="setShopAddress()">
								{{'BUTTON_SELECT' | translate}}
							</button>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>
<app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>
