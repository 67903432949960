import {
	AfterViewInit,
	ChangeDetectionStrategy, ChangeDetectorRef,
	Component, EventEmitter,
	OnInit, Output,
	ViewChild,
	ViewEncapsulation
} from '@angular/core';
import {DashboardService} from "../../../modules/dashboard/dashboard.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {AuthService} from "../../../modules/auth/auth.service";
import {Base} from "../../../core/base";
import {switchMap, takeUntil} from "rxjs/operators";
import {ApiResultModel} from "../../../core/interfaces/api-result.model";
import {GetShopResModel, ShopModel} from "../../../core/interfaces/shop.model";
import {timer} from "rxjs";
import {ShopAddressesService} from "../../../core/services/shop-addresses.service";
import {ModalDirective} from "ngx-bootstrap/modal";
import {PageEvent} from "@angular/material/paginator";
import {Router} from "@angular/router";
import {ShopStateEnum} from "../../../core/enums/shop.enum";

@Component({
	selector: 'app-shop-addresses',
	templateUrl: './shop-addresses.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	styles: ['.modal-footer .mat-mdc-paginator-container > .mat-mdc-paginator-page-size  {display: none}']
})
export class ShopAddressesComponent extends Base implements OnInit, AfterViewInit {
	@ViewChild('modal') modal!: ModalDirective;
	@Output() paginate: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
	pageEvent: PageEvent;
	selectedShop: ShopModel;
	shopForm: FormGroup;
	updatedList = [];
	shops = [];
	isLoading: boolean;
	pagination: any;

	constructor(private dashboardService: DashboardService,
				private authService: AuthService,
				private cdRef: ChangeDetectorRef,
				private fb: FormBuilder,
				private router: Router,
				private shopAddressesService: ShopAddressesService) {
		super();
	}

	ngOnInit(): void {
		this.initShopForm();
		this.searchShops();
	}

	private initShopForm(): void {
		this.shopForm = this.fb.group({
			page: 1,
			size: 10,
			sort: 's.updatedAt',
			order: 'desc',
			search: '',
			state: ShopStateEnum.Active
		})
	}

	ngAfterViewInit(): void {
		this.shopAddressesService.showShopsPopup$.pipe(
			switchMap(shop => {
				this.modal.show();
				return timer(150);
			}),
			takeUntil(this.destroy$),
		).subscribe(() => {
			this.getShops();
		});

		this.modal.onHidden.pipe(takeUntil(this.destroy$)).pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.shops = null;
		});
	}

	private getShops(): void {
		this.isLoading = true;
		this.dashboardService.getShops(this.shopForm.getRawValue()).pipe(takeUntil(this.destroy$)).subscribe((res: ApiResultModel<GetShopResModel>) => {
			this.shops = res.payload.content;
			this.pagination = res.payload.pagination;

			this.updatedList = []

			this.shops.forEach(list => {
				let isChecked = false;
				if (list.id === JSON.parse(localStorage.getItem('shop'))?.id) {
					isChecked = true;
				}

				list = {
					...list,
					isChecked: isChecked
				}
				this.updatedList.push(list);
			})
			if (!localStorage.getItem('shop') && this.shops.length > 0) {
				localStorage.setItem('shop', JSON.stringify(this.shops[0]));
			}
			this.isLoading = false;
			this.cdRef.markForCheck();
		});
	}

	setShopAddress(): void {
		if (this.selectedShop) {
			this.authService.changeShopAddress.next(this.selectedShop);
			localStorage.setItem('shop', JSON.stringify(this.selectedShop));
		}
		this.shopForm.get('search').setValue('');
		this.modal.hide();
	}

	onSelection(shop: ShopModel): void {
		this.selectedShop = shop;
	}

	searchShops(): void {
		this.shopForm.get('search').valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (res: string) => {
					if (res) {
						this.getShops();
					}
				}
			})
	}

	onPaginateChange(event: PageEvent): void {
		event.pageIndex = event.pageIndex + 1;
		this.paginate.emit(event);
		this.shopForm.get('page').setValue(event.pageIndex);
		this.shopForm.get('size').setValue(event.pageSize);
		this.getShops();
	}

	goToCreateShop(): void {
		this.modal.hide();
		this.router.navigateByUrl('dashboard/customer-dashboard/customer/create-edit-shop');
	}

	onClose(): void {
		this.shopForm.get('search').setValue('');
		this.modal.hide();
	}
}
