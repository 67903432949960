import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {CardService} from "../../../order/shopping-cart/card.service";
import {AuthService} from "../../../auth/auth.service";
import {MatDialog} from "@angular/material/dialog";
import {ProductGalleryLayout} from "../../../../shared/components/product-gallery/product-gallery.component";
import {NavigationService} from "../../../../core/services/navigation-service";
import {ProductsContentItemModel} from "../../../../core/interfaces/products/products-data.model";
import {Base} from "../../../../core/base";
import {takeUntil} from "rxjs/operators";
import {ApiResultModel} from "../../../../core/interfaces/api-result.model";
import {AppConfig} from "../../../../core/config/appConfig";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {WishListService} from "../../../order/wish-list/wish-list.service";
import {GlobalService} from "../../../../core/services/global.service";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";

export type PageProductLayout = 'sidebar' | 'full';

@Component({
	selector: 'app-product-item',
	templateUrl: './product-item.component.html'
})
export class ProductItemComponent extends Base implements OnInit {
	productId: string;
	productCount = 1;
	addToCardForm: FormGroup;
	product: ProductsContentItemModel;
	layout: PageProductLayout = 'sidebar';
	shopId: string;
	price: number;
	hasDiscountPrice: number;
	size: 'sm' | 'lg' | null = null;
	options = {
		step: 1,
		min: null as null | number,
		max: null as null | number,
		disabled: false,
		readonly: false,
	};
	isLoading: boolean;
	showWishListPopup: boolean;

	get galleryLayout(): ProductGalleryLayout {
		return `product-${this.layout}` as ProductGalleryLayout;
	}

	constructor(private activatedRoute: ActivatedRoute,
				private cardService: CardService,
				private globalService: GlobalService,
				private authService: AuthService,
				private wishListService: WishListService,
				private toastr: ToastrService,
				private translate: TranslateService,
				private fb: FormBuilder,
				public dialog: MatDialog,
				private navigationService: NavigationService) {
		super();
	}

	ngOnInit(): void {
		this.productId = this.activatedRoute.snapshot.queryParams.id;
		this.initAddToCardForm()

		if (this.authService.isAuthenticated()) {
			this.authService.changeShopAddress.pipe(takeUntil(this.destroy$)).subscribe({
				next: res => {
					this.shopId = res?.id;
					this.getProductById();
					this.initAddToCardForm();
				}
			})
		} else {
			this.getProductById();
		}
	}

	private initAddToCardForm(): void {
		this.addToCardForm = this.fb.group({
			shop: this.shopId,
			productId: this.productId,
			quantity: this.productCount,
			properties: this.fb.group({
				unit: this.fb.group({
					unitId: ['', Validators.required],
					name: ['', Validators.required],
					refId: '',
					contents: ['', Validators.required]
				})
			})
		})
	}

	private getCards(): void {
		const query = {
			shopId: this.shopId
		};
		if (!this.shopId) {
			delete query.shopId;
		}
		this.cardService.getCards(query).pipe(takeUntil(this.destroy$)).subscribe(() => {
		})
	}

	private getProductById(): void {
		this.isLoading = true;
		const query = {
			id: this.productId,
			shopId: this.shopId
		};
		if (!this.shopId) {
			delete query.shopId;
		}
		this.globalService.getProductsByShop(query).pipe(takeUntil(this.destroy$)).subscribe({
			next: (res: ApiResultModel<ProductsContentItemModel>) => {
				this.product = res.payload;
				this.price = this.product?.price;
				this.hasDiscountPrice = this.product?.price - (this.product?.price * this.product?.discount) / 100;
				this.isLoading = false;

				this.product.properties?.unit?.filter(el => {
					if (this.product.unit?.unitCode && el.unitCode === this.product.unit?.unitCode) {
						this.addToCardForm.get('properties').get('unit').patchValue(el);
					}
				})
			},
			error: (error) => {
				this.isLoading = false;
				if (error.status === 400 || error.status === 404) {
					this.navigationService.navigateTo(AppConfig.routes.pages.childRouts.notFound);
				}
			}
		})
	}

	addToWishList(product: any): void {
		if (this.showWishListPopup) {
			return;
		}

		this.showWishListPopup = true;
		this.wishListService.show(product).subscribe({
			complete: () => {
				this.showWishListPopup = false;
			},
		});
	}

	addToCard(): void {
		this.cardService.addToCard(this.addToCardForm.getRawValue()).pipe(takeUntil(this.destroy$)).subscribe({
			next: () => {
				this.getCards();
			},
			error: (err) => {
			}
		});
	}

	plus(): void {
		this.productCount++;
		this.addToCardForm.get('quantity').setValue(this.productCount);
	}

	minus(): void {
		if (this.productCount <= 1) return;
		this.productCount--;
		this.addToCardForm.get('quantity').setValue(this.productCount);
	}

	steQuantityByKeyDown(event: any): void {
		this.productCount = +event;
		this.addToCardForm.get('quantity').setValue(this.productCount);
	}

	goToSupplier(id: number): void {
		this.navigationService.goToSupplier(id)
	}

	setUnit(event: any): void {
		this.price = this.product.price;
		this.hasDiscountPrice = this.product?.price - (this.product?.price * this.product?.discount) / 100;
		this.addToCardForm.get('properties').get('unit').patchValue(event);
		this.price = this.price * event.contents;
		this.hasDiscountPrice = this.hasDiscountPrice * event.contents;
	}
}
