import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {CardService} from "../card.service";
import {AuthService} from "../../../auth/auth.service";
import {ReplaySubject} from "rxjs";
import {
	ShoppingCartItemsModel,
	ShoppingCartUpdateModel
} from "../../../../core/interfaces/shopping-cart/shopping-cart.model";
import {SupplierModel} from "../../../../core/interfaces/users/supplier/supplier.model";
import {ShopModel} from "../../../../core/interfaces/shop.model";
import {Base} from "../../../../core/base";
import {takeUntil} from "rxjs/operators";
import {environment} from "../../../../../environments/environment";

@Component({
	selector: 'app-shopping-cart-page',
	templateUrl: './shopping-cart-page.component.html'
})
export class ShoppingCartPageComponent extends Base implements OnInit {
	itemQuantity = 0;
	isLow: boolean;
	suppliersAmountSubject: ReplaySubject<number> = this.cardService.onSupplierTotalAmountChange;
	suppliersCurrentTotalAmount: number;
	suppliersCurrentRewardAmount: number;
	imagePath = environment.imagePath;
	size: 'sm' | 'lg' | null = null;
	cardItems: ShoppingCartItemsModel[] = [];
	id: string;
	totalReward: number;
	cartTotal: number;
	supplier: SupplierModel;
	shop: ShopModel | any;
	quantity = 1;
	productCount = 1;
	needUpdate: boolean;
	shoppingCarts: any[];
	shopId: number;
	itemUpdatedReqBody: ShoppingCartUpdateModel[] = [];
	changedProducts: { [key: number]: number } = {};
	isLoading: boolean;
	isEmpty: boolean;
    listId: string;

	constructor(
		private activatedRoute: ActivatedRoute,
		private cardService: CardService,
		private authService: AuthService,
		private router: Router
	) {
		super();
	}

	ngOnInit(): void {
		this.authService.changeShopAddress.pipe(takeUntil(this.destroy$)).subscribe(res => {
			this.shopId = res?.id;
			if (this.shopId) {
				this.getCards();
			} else {
				this.isEmpty = true;
			}
		})
	}

	removeProduct(id: string): void {
		this.cardService.removeCard(this.id, id).pipe(takeUntil(this.destroy$)).subscribe((res) => {
			this.getCards();
		})
	}

	plus(index: number, startValue: number, itemId: number): void {
		this.needUpdate = true;
		this.itemUpdatedReqBody.filter(el => {
			if (el.itemId === itemId) {
				if (this.cardItems[index].quantity) {
					this.cardItems[index].quantity++;
				} else {
					this.cardItems[index].quantity = ++startValue;
				}
				el.quantity = this.cardItems[index].quantity;
			}
		})
	}

	minus(index: number, startValue: number, itemId: number): void {
		this.needUpdate = true;
		this.itemUpdatedReqBody.filter(el => {
			if (el.itemId === itemId) {
				if (this.cardItems[index].quantity) {

					if (this.cardItems[index].quantity <= 1) return;
					this.cardItems[index].quantity--;
				} else {
					if (startValue <= 1) return;
					this.cardItems[index].quantity = --startValue;
				}
				el.quantity = this.cardItems[index].quantity;
			}
		})
	}

	review(): void {
		this.router.navigate(['/order/order-review'], {queryParams: {id: this.id}});
	}

	updateCard(): void {
		this.cardService.updateCard(this.id, {cartItems : this.itemUpdatedReqBody}).pipe(takeUntil(this.destroy$)).subscribe((res) => {
			if (res.payload.cartTotal >= res.payload.supplier.supplierSettings.minOrderSum) {
				this.needUpdate = false;
				this.isLow = false;
			} else {
				this.needUpdate = true;
				this.isLow = true;
			}
			this.getCards(this.id);
		})
	}

	steQuantityByKeyDown(index: number, event: string, itemId: number): void {
		this.needUpdate = true;
		this.itemUpdatedReqBody.filter(el => {
			if (el.itemId === itemId) {
				this.cardItems[index].quantity = +event;
				el.quantity = this.cardItems[index].quantity;
			}
		})
	}

	private getCards(id?: string): void {
		this.isLoading = true;
		this.cardService.getCards({shopId: this.shopId}).pipe(takeUntil(this.destroy$))
			.subscribe({
			next: res => {
				this.shoppingCarts = res.payload.content;
				this.isEmpty = this.shoppingCarts.length === 0;
				this.getCardById(id || this.shoppingCarts[0]?.id);
				this.isLoading = false;
				this.listId = this.shoppingCarts[0]?.id;
			}
		}).add(() => this.isLoading = false)
	}

	getCardById(id: string): void {
		this.isLoading = true;
		if (id) {
			this.cardService.getCardById(id).pipe(takeUntil(this.destroy$)).subscribe({
				next: res => {
					this.cardItems = res.payload.cartItems;
					this.isEmpty = this.cardItems.length === 0;
					this.supplier = res.payload.supplier;
					this.shop = res.payload.shop;
					this.totalReward = res.payload.totalReward;
					this.cartTotal = res.payload.cartTotal;
					this.id = id;
					this.listId = id;
					this.itemUpdatedReqBody = this.cardItems.map(el => {
						return {
							itemId: el.id,
							quantity: el.quantity
						}
					})

					if (this.cartTotal < this.supplier.supplierSettings.minOrderSum) {
						this.isLow = true;
					}
					this.isLoading = false;
				},
				error: () => {
					this.isLoading = false;
				}
			})
		} else {
			this.cardItems = [];
		}

	}

	removeCart(id: string): void {
		this.cardService.removeAllCard(id).pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.getCards();
		})
	}
}
