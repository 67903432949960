import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SharedModule} from "./shared/shared.module";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {DashboardModule} from "./modules/dashboard/dashboard.module";
import {HomeModule} from "./modules/home/home.module";
import {AuthModule} from "./modules/auth/auth.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule, registerLocaleData} from "@angular/common";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CountdownModule} from "ngx-countdown";
import {JwtModule} from "@auth0/angular-jwt";
import {JwtInterceptor} from "./core/interceptor/interceptor";
import {LayoutModule} from "./modules/layout/layout.module";
import {MobileModule} from "./modules/mobile/mobile.module";
import en from '@angular/common/locales/en';
import ru from '@angular/common/locales/ru';
import hy from '@angular/common/locales/hy';
import {NgMaterialModule} from "./ng-material.module";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule} from "@angular/material/core";
import {
	MAT_MOMENT_DATE_ADAPTER_OPTIONS,
	MAT_MOMENT_DATE_FORMATS,
	MomentDateAdapter
} from "@angular/material-moment-adapter";
import {MatPaginatorIntl} from "@angular/material/paginator";
import {MatPaginationIntlService} from "./core/services/paginator.service";
import {ModalModule} from "ngx-bootstrap/modal";
import {ProductModule} from "./modules/product/product.module";
import {OrderModule} from "./modules/order/order.module";
import {AuthInterceptor} from "./core/interceptor/anuthorized.interceptor";
import {ToastrModule} from "ngx-toastr";

registerLocaleData(en);
registerLocaleData(ru);
registerLocaleData(hy);

export function tokenGetter() {
	return localStorage.getItem("access_token");
}

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserAnimationsModule,
		CountdownModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		BrowserModule,
		AppRoutingModule,
		HomeModule,
		AuthModule,
		LayoutModule,
		DashboardModule,
		SharedModule,
		ToastrModule.forRoot(),
		ModalModule.forRoot(),
		NgMaterialModule,
		MatNativeDateModule,
		MobileModule,
		JwtModule.forRoot({
			config: {
				tokenGetter
			},
		}),
		HttpClientModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			},
			defaultLanguage: localStorage.getItem('locale') || 'hy',
		}),
		BrowserAnimationsModule,
		ProductModule,
		OrderModule
	],
	providers: [
		{
			provide: MatPaginatorIntl,
			useClass: MatPaginationIntlService,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: JwtInterceptor,
			multi: true
		},
		{provide: MAT_DATE_LOCALE, useValue: localStorage.getItem('locale')},
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
		},
		{provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
	return new TranslateHttpLoader(http);
}
