<div class="header">
	<div class="header__topbar-start-bg"></div>
	<div class="header__topbar-start">
				<div class="topbar topbar--spaceship-start">
<!--					<div class="topbar__item-text d-none d-xxl-flex">-->
<!--						<a class="topbar__link">{{ 'TEXT_TOPBAR_PHONE' | translate}} (800) 060-0730</a>-->
<!--					</div>-->
<!--					<div class="topbar__item-text">-->
<!--						<a class="topbar__link" routerLink="pages/about-us">{{ 'LINK_ABOUT_US'|translate }}</a>-->
<!--					</div>-->
<!--					<div class="topbar__item-text">-->
<!--						<a class="topbar__link" routerLink="pages/contact-us">{{ 'LINK_CONTACTS'|translate }}</a>-->
<!--					</div>-->
					<div class="topbar__item-text">
						<a class="topbar__link" target="_blank" [href]="sellerUrl">{{ 'BECOME_A_SELLER'|translate }}</a>
					</div>
				</div>
	</div>
	<div class="header__topbar-end-bg"></div>
	<div class="header__topbar-end">
		<div class="topbar topbar--spaceship-end">
<!--			<div class="topbar__item-button">-->
<!--				<a class="topbar__button" href="">-->
<!--					<span class="topbar__button-label">{{ 'TEXT_TOPBAR_COMPARE'|translate }}:</span>-->
<!--					<span class="topbar__button-title">0</span>-->
<!--				</a>-->
<!--			</div>-->
<!--			<div class="topbar__item-button topbar__menu">-->
<!--				<button class="topbar__button topbar__button&#45;&#45;has-arrow topbar__menu-button">-->
<!--					<span class="topbar__button-label">{{ 'TEXT_TOPBAR_CURRENCY'|translate }}:</span>-->
<!--					<span class="topbar__button-title">USD</span>-->
<!--					<span class="topbar__button-arrow">-->
<!--            <span icon="arrow-down-sm-7x5"></span>-->
<!--          </span>-->
<!--				</button>-->
<!--			</div>-->
			<div class="topbar__item-button topbar__menu">
				<button class="topbar__button topbar__button--has-arrow topbar__menu-button">
					<span class="topbar__button-label">{{ 'TEXT_TOPBAR_LANGUAGE'|translate }}:</span>
					<select #langSelect (change)="changeLang(langSelect.value)">
						<option *ngFor="let lang of translate.getLangs()" [value]="lang"
								[selected]="lang === translate.currentLang">{{ lang | uppercase }}
						</option>
					</select>
					<span class="topbar__button-arrow"></span>
				</button>
				<div class="topbar__menu-body"></div>
			</div>
		</div>
	</div>
	<div class="header__navbar">
		<div class="header__navbar-departments">
			<app-categories-menu (clickOutside)="hideCategoryMenu()"></app-categories-menu>
		</div>
		<div class="header__navbar-menu">
			<div>
				<ul class="main-menu__list">
					<li class="main-menu__item main-menu__item--has-submenu main-menu__item--submenu--menu">
						<a class="main-menu__link" routerLink='/pages/all-suppliers-page'>{{ "SUPPLIERS" | translate }}</a>
					</li>
					<li class="main-menu__item main-menu__item--has-submenu main-menu__item--submenu--menu"
						routerLink="product/category-list">
						<a class="main-menu__link">{{ "HEADER_SHOP" | translate }}
						</a>
					</li>
					<form [formGroup]="form" *ngIf="isAuthenticated">
						<li class="main-menu__item main-menu__item--has-submenu main-menu__item--submenu--menu">
							<input formControlName="shop" placeholder="{{'SHOP' | translate}}" class="main-menu__link"
								   style="cursor: pointer; outline: none; border: none; max-width: 170px" readonly
								   (click)="selectShop()">
						</li>
					</form>

				</ul>
			</div>
		</div>
	</div>
	<div class="header__logo">
		<a class="logo" (click)="goToHome()">
			<div class="logo__image">
				<img src="../../../../assets/images/logo.png" height="65px">
			</div>
		</a>
	</div>
	<div class="header__search">
		<app-header-search></app-header-search>
	</div>
	<app-header-indicators class="header__indicators"></app-header-indicators>
</div>
