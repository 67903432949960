<div class="products-view__body">
	<div class="products-view__loader"></div>
	<div *ngIf="shownProducts?.length < 1" class="products-view__empty">
		<div class="products-view__empty-title">
			{{ 'TEXT_NO_MATCHING_ITEMS_TITLE'|translate }}
		</div>
	</div>
	<ng-container>
		<div
			class="products-view__options view-options"
			[class.view-options--offcanvas--always]="offCanvasSidebar === 'always'"
			[class.view-options--offcanvas--mobile]="offCanvasSidebar === 'mobile'"

		>
			<div class="view-options__body">
				<button type="button" class="view-options__filters-button filters-button" (click)="sidebar.open()">
					<span class="filters-button__icon"><app-icons icon="filters-16"></app-icons></span>
					<span class="filters-button__title">{{ 'BUTTON_FILTERS'|translate }}</span>
				</button>

				<div class="view-options__layout layout-switcher">
					<div class="layout-switcher__list">
						<button
							*ngFor="let layoutButton of layoutButtons"
							type="button"
							class="layout-switcher__button"
							[class.layout-switcher__button--active]="layoutButton.layout === layout"
							(click)="setLayout(layoutButton.layout)"
						>
							<app-icons [icon]="layoutButton.icon"></app-icons>
						</button>
					</div>
				</div>
				<div class="view-options__spring"></div>

				<div class="view-options__select">
					<label for="view-option-sort">{{ 'INPUT_SORT_LABEL'|translate }}:</label>
					<select id="view-option-sort" class="form-control form-control-sm" [formControl]="sortControl"
							(change)="sort()">
						<option value="default">{{ 'INPUT_SORT_OPTION_DEFAULT'|translate }}</option>
						<option value="name_asc">{{ 'INPUT_SORT_OPTION_NAME_ASC'|translate }}</option>
						<option value="name_desc">{{ 'INPUT_SORT_OPTION_NAME_DESC'|translate }}</option>
						<option value="price_asc">{{ 'INPUT_SORT_OPTION_PRICE_ASC'|translate }}</option>
						<option value="price_desc">{{ 'INPUT_SORT_OPTION_PRICE_DESC'|translate }}</option>
					</select>
				</div>
				<div class="view-options__select">
					<label for="view-option-limit">{{ 'INPUT_LIMIT_LABEL'|translate }}:</label>
					<select id="view-option-limit" class="form-control form-control-sm" [formControl]="limitControl">
						<option value="8">8</option>
						<option value="16">16</option>
						<option value="24">24</option>
						<option value="32">32</option>
					</select>
				</div>
			</div>

			<div class="view-options__body view-options__body--filters" *ngIf="hasActiveFilters$|async">
				<div class="view-options__label">{{ 'TEXT_ACTIVE_FILTERS'|translate }}</div>
				<div class="view-options__applied-filters applied-filters">
					<ul class="applied-filters__list">
						<li *ngFor="let filter of page.currentFilters$|async; trackBy: trackById" class="applied-filters__item">
							<button
								type="button"
								class="applied-filters__button applied-filters__button--filter"
								(click)="page.resetFilter(filter)"
							>
								{{ filter|activeFilterLabel }}

								<app-icon icon="cross-9-light"></app-icon>
							</button>
						</li>
						<li class="applied-filters__item">
							<button
								type="button"
								class="applied-filters__button applied-filters__button--clear"
								(click)="page.resetAllFilters()"
							>{{ 'BUTTON_CLEAR_ALL'|translate }}</button>
						</li>
					</ul>
				</div>
			</div>
		</div>

		<div
			class="products-view__list products-list"
			[attr.data-layout]="layout === 'grid-with-features' ? 'grid' : layout"
			[attr.data-with-features]="layout === 'grid-with-features' ? 'true' : 'false'"
			[class.products-list--grid--6]="gridLayout === 'grid-6-full'"
			[class.products-list--grid--5]="gridLayout === 'grid-5-full'"
			[class.products-list--grid--4]="['grid-4-full', 'grid-4-sidebar'].includes(gridLayout)"
			[class.products-list--grid--3]="gridLayout === 'grid-3-sidebar'"

		>
			<div class="products-list__head">
				<div class="products-list__column products-list__column--image">{{ 'TABLE_IMAGE'|translate }}</div>
				<div class="products-list__column products-list__column--meta">{{ 'TABLE_SKU'|translate }}</div>
				<div class="products-list__column products-list__column--product">{{ 'TABLE_PRODUCT'|translate }}</div>
				<div class="products-list__column products-list__column--rating">{{ 'TABLE_RATING'|translate }}</div>
				<div class="products-list__column products-list__column--price">{{ 'TABLE_PRICE'|translate }}</div>
			</div>
			<div class="products-list__content">
				<div *ngFor="let product of shownProducts | paginate: { itemsPerPage: limitControl.value, currentPage:p }" class="products-list__item">
					<app-product-card [product]="product"></app-product-card>
				</div>
			</div>
		</div>

		<div class="products-view__pagination">
			<nav aria-label="Page navigation example">
				<pagination-controls previousLabel="{{'PREVIOUS' | translate}}"
									 nextLabel="{{'NEXT' | translate}}" (pageChange)="p = $event"></pagination-controls>
			</nav>
		</div>
	</ng-container>
</div>
