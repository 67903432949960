<ng-container>
	<div class="product-card__image">
		<div class="image image--type--product">
			<a (click)="goToSupplierBusinessPage(supplier.id)" class="image__body">
				<img class="image__tag"
					 [src]="supplier?.logoType ? (imagePath + supplier?.logoType?.thumbnail?.url) : 'assets/images/defaultSupplierOrCustomer.jpg'"
					 alt="">
			</a>
		</div>
	</div>

	<div class="product-card__info">
		<div class="product-card__meta">
		</div>

		<div class="product-card__name truncate">
			<div>
				<a (click)="goToSupplierBusinessPage(supplier?.id)"><b>{{ supplier?.companyName }}</b></a>
			</div>
		</div>

		<div class="product-card__rating">
		</div>
	</div>

	<div class="product-card__footer">
		<div class="product-card__prices">
		</div>
		<button class="product-card__partners-icon" *ngIf="supplier.partnershipStatus === partnershipStatus.Pending" (click)="sendRequest(supplier.id)">
					<mat-icon>access_time</mat-icon>
		</button>
		<div class="product-card__partners-icon" *ngIf="supplier.partnershipStatus === partnershipStatus.Accepted"><mat-icon>people</mat-icon>
		</div>
		<button *ngIf="supplier.partnershipStatus === partnershipStatus.NotSend"
				type="button"
				class="product-card__partners-icon"
				[class.product-card__addtocart-icon--loading]="cartInProgress"
				(click)="sendRequest(supplier.id)">
			<mat-icon>group_add</mat-icon>
		</button>
	</div>
</ng-container>
