import {Component, Inject, NgZone, PLATFORM_ID} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {DesktopHeaderVariant, HeaderService, MobileHeaderVariant} from "./core/services/header.service";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Observable} from "rxjs";
import {isPlatformBrowser} from "@angular/common";
import {filter, first, takeUntil} from "rxjs/operators";
import {Base} from "./core/base";
import {AuthService} from "./modules/auth/auth.service";

export interface RootComponentData {
	desktopHeader: DesktopHeaderVariant;
	mobileHeader: MobileHeaderVariant;
}

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent extends Base{
	title = 'my-app';

	constructor(
		@Inject(PLATFORM_ID) private platformId: any,
		private translate: TranslateService,
		private route: ActivatedRoute,
		public header: HeaderService,
		public authService: AuthService,
		private zone: NgZone,
		private router: Router)
	{
		super();
		translate.addLangs(['hy', 'ru']);

		if (localStorage.getItem('locale')) {
			const browserLang = localStorage.getItem('locale');
			translate.use(browserLang.match(/en|ru|am/) ? browserLang : 'hy');
		} else {
			localStorage.setItem('locale', 'hy');
			translate.setDefaultLang('hy');}
	}

	ngOnInit(): void {
		const data$ = this.route.data as Observable<RootComponentData>;

		data$.pipe(takeUntil(this.destroy$)).subscribe((data: RootComponentData) => {
			this.header.setDesktopVariant(data.desktopHeader || 'spaceship/one');
			this.header.setMobileVariant(data.mobileHeader || 'one');
		});

		if (isPlatformBrowser(this.platformId)) {
			this.zone.runOutsideAngular(() => {
				this.router.events.pipe(
					filter(event => event instanceof NavigationEnd),
					first(),
					takeUntil(this.destroy$)
				).subscribe(() => {
					const preloader = document.querySelector('.site-preloader');

					if (!preloader) {
						return;
					}

					preloader.addEventListener('transitionend', (event: Event) => {
						if (event instanceof TransitionEvent && event.propertyName === 'opacity') {
							preloader.remove();
							document.querySelector('.site-preloader-style')?.remove();
						}
					});
					preloader.classList.add('site-preloader__fade');

					if (getComputedStyle(preloader).opacity === '0' && preloader.parentNode) {
						preloader.parentNode.removeChild(preloader);
					}
				});
			});
		}
	}
}
