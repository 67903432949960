<ng-container *ngIf="shoppingCard.length <= 0">
	<div class="block-empty">
		<div class="container">
			<div class="block-empty__body">
				<h2 class="block-empty__title">{{ 'HEADER_SHOPPING_CART_EMPTY_TITLE'|translate }}</h2>
			</div>
		</div>
	</div>
</ng-container>
<ng-container *ngIf="shoppingCard.length > 0">
	<ul class="dropcart__list">
		<ng-container *ngFor="let item of shoppingCard">
			<li class="dropcart__item" (click)="goToCardPage(item.id)">
				<div class="dropcart__item-info">
					<div class="dropcart__item-name">{{item.shop.companyName}}</div>
				</div>
				<div class="dropcart__item-info">
					<div class="dropcart__item-name">
						<a>{{item.supplier.companyName}}</a>
					</div>
					<div class="dropcart__item-meta">
						<div class="dropcart__item-quantity">{{item.cartItems.length}}</div>
						<div class="dropcart__item-price">֏{{item.cartTotal}}</div>
					</div>
				</div>
				<button
					type="button"
					class="dropcart__item-remove"
					(click)="removeCard($event, item.id)"
				>
					<span>x</span>
				</button>
			</li>
		</ng-container>
	</ul>
</ng-container>
